import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { LoginRedirectService } from '@shared/services';
import { AuthService } from '@shared/services/auth.service';

export const authGuard: CanActivateFn = (): boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const location = inject(Location);
  const loginRedirectService = inject(LoginRedirectService);

  if (authService.loggedIn()) {
    return true;
  }
  loginRedirectService.setLoginRedirectUrl(location.path());
  router.navigate(['/login']);
  return false;
};
